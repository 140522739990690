import React, { Component } from "react";
import { Redirect } from 'react-router'
import Header from "./Header";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";

export default class User extends Component {
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
          serials: [],
          timestamps:[],
        showComponent: false,
        matches: window.matchMedia("(min-width: 768px)").matches
    };
    this.verification = this.verification.bind(this);
  }

  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);

      const payRef = db.ref("payement")
    payRef.once('value', (serials)=>{
      serials.forEach((serial)=>{
        console.log(serial.key)
          const serialRef = db.ref("Devices/"+serial.key)
          serialRef.orderByKey().limitToFirst(1).once('value', (temps) => {
            console.log(temps.val())
            temps.forEach((temp)=> {
              console.log(temp.val()[1])
              if (Date.now()-temp.val()[1] >= 1800000){
                const keyRef = db.ref("Devices/"+serial.key+"/"+temp.key)
                console.log(keyRef)
                keyRef.remove()
                serialRef.orderByKey().limitToFirst(1).once('value', (temps) => {
                  console.log(temps.val())
                  temps.forEach((temp)=> {
                    console.log(temp.val()[1])
                    if (Date.now()-temp.val()[1] >= 1800000){
                      const keyRef = db.ref("Devices/"+serial.key+"/"+temp.key)
                      keyRef.remove()
                      serialRef.orderByKey().limitToFirst(1).once('value', (temps) => {
                        console.log(temps.val())
                        temps.forEach((temp)=> {
                          console.log(temp.val()[1])
                          if (Date.now()-temp.val()[1] >= 1800000){
                            const keyRef = db.ref("Devices/"+serial.key+"/"+temp.key)
                            keyRef.remove()
                            serialRef.orderByKey().limitToFirst(1).once('value', (temps) => {
                              console.log(temps.val())
                              temps.forEach((temp)=> {
                                console.log(temp.val()[1])
                                if (Date.now()-temp.val()[1] >= 1800000){
                                  const keyRef = db.ref("Devices/"+serial.key+"/"+temp.key)
                                  keyRef.remove()
                                  serialRef.orderByKey().limitToFirst(1).once('value', (temps) => {
                                    console.log(temps.val())
                                    temps.forEach((temp)=> {
                                      console.log(temp.val()[1])
                                      if (Date.now()-temp.val()[1] >= 1800000){
                                        const keyRef = db.ref("Devices/"+serial.key+"/"+temp.key)
                                        keyRef.remove()
                                        serialRef.orderByKey().limitToFirst(1).once('value', (temps) => {
                                          console.log(temps.val())
                                          temps.forEach((temp)=> {
                                            console.log(temp.val()[1])
                                            if (Date.now()-temp.val()[1] >= 1800000){
                                              const keyRef = db.ref("Devices/"+serial.key+"/"+temp.key)
                                              keyRef.remove()
                                            }
                                          })
                                        })
                                      }
                                    })
                                  })
                                }
                              })
                            })
                          }
                        })
                      })
                    }
                  })
                })
              }
            })
          })
      })
    })
  }

  verification(event){
    event.preventDefault();
    let user = auth().currentUser;
    console.log("in verification")
    user.sendEmailVerification().then(function() {
      console.log("email sent")
    }).catch(function(error) {
      console.log("error")
    });
  }

  render() {
    return (
      <div class="wrapper">
    <Sidenav/>
    <div id="content">
      {this.state.matches && (<Header/>)}
      {!this.state.matches && (<Navbar />)}
      <div class="space">
      <h5>Logged in as: <strong>{this.state.user.email}</strong></h5>
      <h6><Link to="/ChangePassword">Change Password</Link></h6>
      </div>
    </div>
</div>
    );
  }
}