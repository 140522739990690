import React, { Component, useState } from "react";
import { auth, db } from "../firebase";
import Card from 'react-bootstrap/Card';
import "./Sidebar.css";
import { Redirect } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThermometerThreeQuarters, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

export default class PrintSerial extends Component{
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
      serials: [],
      last_temps: [],
      matches: window.matchMedia("(min-width: 768px)").matches
    };
    this.deleteCard = this.deleteCard.bind(this);
  }

deleteCard=(value)=>{
  //console.log(value)
  const uid = this.state.user.uid
  const sRef= db.ref(uid + '/serials/')

  const serialQuery = sRef.orderByChild("serial").equalTo(value).on("child_added", function(snapshot) {
    //console.log(snapshot.key);
    const seRef = db.ref(uid + '/serials/' + snapshot.key).remove()
  })
  const gw = db.ref('gateways')
  gw.once('value', (devs)=>{
    devs.forEach((dev)=>{
    dev.forEach((ble)=>{
      console.log(dev.key)
      console.log(ble.key)
      console.log(ble.val().serial)
      if(ble.val().serial === value){
        console.log('yes')
        console.log('gateways/' + dev.key + '/' + ble.key + '/' + value)
        const bleRef = db.ref('gateways/' + dev.key + '/' + ble.key ).remove()
      }
    })
    })
  })
  //window.location.reload()
}

  componentDidMount() {
    
    console.log("HOCEM PRINTAT")
    var stevnik = 0
    db.ref('Devices/421').orderByKey().limitToLast(480).on('value', (temps) => {
      // if(stevnik === 479){
      console.log(temps.val())
      temps.forEach((temp) => {
        console.log("v loopu")
        console.log(temp.val())
      })
    // }
    // stevnik++
    })
    
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
    const uid = this.state.user.uid;
    const serialRef = db.ref(uid + '/serials');

    serialRef.on('value', (serial_numbers) => {
      const serials = [];
      serial_numbers.forEach((serial_number) => {
        //console.log(serial_number.val());
        const za = serial_number.val();
        const ta = za.serial;
        //console.log(ta);
        const last_temps = [];
        const devicename = [];
        const phone = [];
        const alarm = [];
        const nameRef = db.ref(ta + '/devicename');
        nameRef.on('value', (dname)=> {
          devicename.push(dname.val());
          this.setState(devicename );
        })
        const phoneRef = db.ref(ta + '/phone')
        phoneRef.on('value', (pname)=> {
          console.log(pname.val())
          var telefon = "+" + pname.val()
          console.log(telefon)
          phone.push(pname.val())
        })
        const alarmRef = db.ref(ta + '/alarm')
        alarmRef.on('value', (aname) =>{
         alarm.push(aname.val())
        })
        const tempRef = db.ref('Devices/' + ta);
        tempRef.orderByKey().limitToLast(1).on('value', (temps) => {
          const temparray = [];
          temps.forEach((temp) => {
            temparray.push(temp.val());
          })
          const last_temp = temparray;
          last_temps.push({ l: last_temp[0][0], ta, d: devicename, p: phone, a: alarm });
          this.setState({ last_temps: last_temps })
        })
        //console.log(last_temps);
        serials.push({ s: last_temps });
        //console.log(serials);
      });
      this.setState({ serials: serials });
    });
  }


  render() {
    return (
      <div>
         {this.state.matches && (
          <div class="kartice">
            <div className="row">
          {this.state.serials.map((number) => {
            console.log(number.s.length)
    return number.s && number.s.length? (
      <div className="col-md-6">
    <Card className="card" style={{ width: '60%' }}>
      <Card.Body>
        <div className="row">
        <div className="col" >
        <h2 ><FontAwesomeIcon icon={faThermometerThreeQuarters}/> {number.s[0].d[0]}</h2>
        Serial number: {number.s[0].ta}<br/>
        Alarm temperature: {number.s[0].a[0]}<br/>
        Phone number: {number.s[0].p[0]}<br/>
        <button class="btn btn-light" onClick={()=>this.deleteCard(number.s[0].ta)}><FontAwesomeIcon icon={faTrashAlt} /> Delete</button>
        </div>
        <div className="vl"></div>
          <div className="col">
        <h1 id="temperatura">{number.s[0].l} °C</h1>
        </div>
        </div>
        </Card.Body>
    </Card>
    <p></p>
    </div>
  ) : null
})}
      </div>
      </div>
         )}
        {!this.state.matches && (
          <div>

          {this.state.serials.map((number) => {
    return number.s && number.s.length? (
      <div>
    <Card className="phoneCard" style={{ width: '90%' }}>
      <Card.Body>
      <div className="row">
        <div className="col">
        <h2 >{number.s[0].d[0]} <FontAwesomeIcon icon={faThermometerThreeQuarters}/></h2>
        Serial number: {number.s[0].ta}<br/>
        Alarm temperature: {number.s[0].a[0]}<br/>
        Phone number: {number.s[0].p[0]}<br/>
        <button class="btn btn-light" onClick={()=>this.deleteCard(number.s[0].ta)}><FontAwesomeIcon icon={faTrashAlt} /> Delete</button>
        </div>
        <div className="vl"></div>
          <div className="col">
        <h1 id="temperatura">{number.s[0].l} °C</h1>
        
        </div>
        </div>
        </Card.Body>
    </Card>
    <p></p>
    </div>
  ) : null
})}

      </div>
        )}
      </div>
    );
  }
  }
  /*
<button className="btn btn-primary rounded-pill px-5" onClick={()=>this.deleteCard(number.s[0].ta)}>Delete Serial</button>

  render() {
    return (
        <div>

          {this.state.serials.map((number) => {
    return number.s && number.s.length? (
      <div>
    <Card className="card" style={{ width: '18rem' }}>
      <Card.Body>
        <h4>{number.s[0].l}</h4><h4>{number.s[0].d[0]}</h4>
        {number.s[0].ta} 
        <div className="vl"></div>
        <FontAwesomeIcon onClick={()=>this.deleteCard(number.s[0].ta)} icon={faTrashAlt} />
        </Card.Body>
    </Card>
    <p></p>
    </div>
  ) : null
})}

      </div>
    );
  }
  }


    {this.state.serials.map((number) => {
    return number.s && number.s.length? (
      <div>
    <Card className="card" style={{ width: '30rem' }}>
      <Card.Body>
        <div className="row">
        <div className="col">
        <h2>{number.s[0].d[0]} <FontAwesomeIcon icon={faThermometerThreeQuarters}/></h2>
        Serial number: {number.s[0].ta} 
        <FontAwesomeIcon onClick={()=>this.deleteCard(number.s[0].ta)} icon={faTrashAlt} />
        </div>
        <div className="vl"></div>
          <div className="col">
        <h1 id="temperatura">{number.s[0].l} °C</h1>
        </div>
        </div>
        </Card.Body>
    </Card>
    <p></p>
    </div>
  ) : null
})}
*/