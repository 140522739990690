import React, { Component } from "react";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import Header from "./Header";
import "./Sidebar.css";
import customizable from "../images/customizable.gif"
import integration from "../images/integration.gif"
import secure from "../images/secure.gif"
import thermometer from "../images/frozen-food.jpg"
import haccp from "../images/Slika1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCircle, faLaptop, faHeart, faCloud, faPhone, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import {MDBAnimation} from 'mdbreact'
import ScrollAnimation from 'react-animate-on-scroll';


export default class HomePage extends Component {
  constructor() {
    super();
    this.state = {
        matches: window.matchMedia("(min-width: 768px)").matches,
        isTop: true,
    };
  }

  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
    this.setState({isTop: true})
    console.log(this.state.isTop)
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
          console.log(isTop)
      }
    });
  }


  render() {
    return (
      <div>
        {auth().currentUser ? (
          <nav className={ this.state.isTop ? "navbar fixed-top navbar-expand-md navbar-light bg-transparent" : "navbar fixed-top navbar-expand-md navbar-light bg-white"} 
          id={this.state.isTop ? "head" : null}  >
        <Link className="navbar-brand" to="/">
        {this.state.matches &&(
                  <h2 class="naslov" style={{color: this.state.isTop ? 'white' : 'black', fontFamily: "Syncopate"}}><b>MEASURES HUB</b></h2>
                )}
                {!this.state.matches &&(
                  <z class="naslov" style={{color: this.state.isTop ? 'white' : 'black', fontFamily: "Syncopate"}}><b>MEASURES HUB</b></z>
                )}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
                  
                    <div class="navbar-nav">
                      <Link id="headerProfile" className="nav-item nav-link" style={{color: this.state.isTop ? 'white' : 'black'}} to="/User" ><FontAwesomeIcon icon={faUser} /> <b>PROFILE</b>
                    </Link>
                    <p id="headerProfile" className="nav-item nav-link" style={{color: this.state.isTop ? 'white' : 'black'}} onClick={() => auth().signOut()}><b><FontAwesomeIcon icon={faSignOutAlt}/> LOGOUT</b></p>
                    </div>
                    </div>
              </nav>
        ):(
          <Header top={this.state.isTop}/>
        )}

          <ScrollAnimation animateIn="fadeIn">
      <div class="content">
        {this.state.matches && ( <div class='prva_slika'>
          {/* <div style={{paddingTop: '20%', paddingLeft: '10%', color:'white', backgroundColor: 'rgba(0, 0, 0, 0.109)', WebkitFilter: 'blur(5px)'}}>
            <text style={{fontSize:40}}>Real-time temperature monitoring combined<br /> with alarm notifications to act immediately.</text></div> */}
          {/* <div class="blur" style={{color: "white"}}>
            <span style={{fontSize:40}}>Real-time temperature monitoring combined<br /> with alarm notifications to act immediately.</span></div>  */}
         {/* <div class="parent">
    <h1>Coming Soon</h1>
    <span class="blur"></span>
</div> */}
<div style={{paddingTop: '20%', paddingLeft: '10%', color:'white'}}>
            <text style={{fontSize:40, fontFamily: "Oswald"}}>Real-time temperature monitoring combined<br /> with alarm notifications to act immediately.</text></div>
  </div>
  )}
 {!this.state.matches && (
   <div class='prva_slika_telefon'>
   </div>
 )}
  {/* style={ {height: '300px'}} */}

  {this.state.matches && (
  <ScrollAnimation animateIn="fadeIn">
  <div className="container-fluid">
 
    <div className="container" style={{marginTop: '5%', marginBottom: '5%'}}>
    <div className="row" >
<div className="col" >
  <span class="fa-stack fa-4x">
  <i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-book fa-stack-1x fa-inverse"></i>
  </span>
      <h2>Automatic data logging</h2>
</div>
<div className="col">
<span class="fa-stack fa-4x">
  <i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-laptop fa-stack-1x fa-inverse"/>
  </span>
  
      <h2>24/7 real-time temperature data</h2>
     
</div>
<div className="col">
<span class="fa-stack fa-4x">
<i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-heart fa-stack-1x fa-inverse"/>
  </span>
      <h2>User friendly alarm notifications</h2>
</div>
<div className="col">
<span class="fa-stack fa-4x">
<i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-cloud fa-stack-1x fa-inverse"/>
  </span>
      <h2>History in the cloud</h2>
</div>
</div>
</div>
  </div>
  </ScrollAnimation>
    )}
    {!this.state.matches && (
      <ScrollAnimation animateIn="fadeIn">
  <div className="container-fluid">
 
    <div className="container" style={{marginTop: '5%', marginBottom: '5%'}}>
<div className="col" >
  <span class="fa-stack fa-4x">
  <i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-book fa-stack-1x fa-inverse"></i>
  </span>
      <h2 >Automatic data logging</h2>
</div>
<div className="col">
<span class="fa-stack fa-4x">
  <i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-laptop fa-stack-1x fa-inverse"/>
  </span>
  
      <h2>24/7 real-time temperature data</h2>
     
</div>
<div className="col">
<span class="fa-stack fa-4x">
<i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-heart fa-stack-1x fa-inverse"/>
  </span>
      <h2>User friendly</h2>
</div>
<div className="col">
<span class="fa-stack fa-4x">
<i class="fas fa-circle fa-stack-2x"/>
  <i class="fas fa-cloud fa-stack-1x fa-inverse"/>
  </span>
      <h2>History in the cloud</h2>
</div>
</div>
  </div>
  </ScrollAnimation>
)}
  <ScrollAnimation animateIn="fadeIn">
  <div className="container-fluid" style={{ backgroundColor: "#eee" , paddingTop:'30px', paddingBottom:'20px'}}>
    <div className="container">
      <div className="row">
      <div className="col-md-6">
      <h2>Reliably documented</h2>
        <p>If you deal with food, you know it’s imperative to get temperature right. That’s why you have to clean the antipasto display case and test the refrigeration system. And if you’re serving food to customers, you can’t afford to make a mistake.
        </p><p>We offer economical HACCP documentation for cold rooms, wall refrigerators, deep-freezers and refrigeration units of all types. If someone leaves a fridge door open for too long or if the evaporator ices up, you get a notification straight away.
        </p>
        </div>
        <div className="col-md-6">
        <p>Our automated documentation will sort out your procedures, and save you time and a lot of money. </p>
        <div><img id="thermometer" src={thermometer}></img>
        </div>
          </div>
          </div>
          </div>
  </div>
  </ScrollAnimation>

  <ScrollAnimation animateIn="fadeIn">
  <div className="container-fluid" class="partner">

{/* <h6 style={{  textAlign: 'center', verticalAlign: 'middle', lineHeight: '400px'}}>Our partners</h6> */}
{this.state.matches && (
<img src={haccp} class="haccp_logo" style={{maxHeight: '70%', maxWidth: '100%', marginLeft: '27%', marginTop: '50px'}}></img>
)}
{!this.state.matches && (
  <img src={haccp} class="haccp_logo" style={{maxHeight: '70%', maxWidth: '100%', marginTop: '12%', marginLeft: '8.5%'}}></img>
)}
  </div>
  <div className="container-fluid">
  <iframe src="https://maps.google.com/maps?q=grosuplje&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  </div>
  </ScrollAnimation>
 <footer class="footer">
 <div className="container" style={{paddingTop: '100px'}}>
 <div className="copyright2">

<p><b>CONTACT MEASURES HUB</b></p>
<div className="crta"></div>
      {/* <p>info@measureshub.com</p> */}
      <p>sales@measureshub.com</p>
    </div>

   
          <div className="copyright">
      
            <p  style={{fontSize: 'medium'}}>©All Rights Reserved.</p>
              <p  style={{fontSize: 'medium'}}>Designed by MeasuresHub</p>
              
              </div>
      </div>
 </footer>
 </div>
 </ScrollAnimation>
      </div>
    );
  }
}

