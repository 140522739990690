import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
 
export default class Scroll extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isTop: true,
            matches: window.matchMedia("(min-width: 768px)").matches,
        };
      }

      componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
      }
     
      render(){
          return(
            <header>
            {auth().currentUser ? (
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to="/">
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNavAltMarkup"
              >
                
                  <div class="navbar-nav">
                    <Link id="headerProfile" className="nav-item nav-link" to="/User" ><FontAwesomeIcon icon={faUser} /> <b>PROFILE</b>
                    </Link>
                    {/* <button
                      className="btn btn-light"
                      onClick={() => auth().signOut()}
                    ><FontAwesomeIcon icon={faSignOutAlt}/> Logout
                    </button> */}
                    <p id="headerProfile" className="nav-item nav-link" onClick={() => auth().signOut()}><b><FontAwesomeIcon icon={faSignOutAlt}/> LOGOUT</b></p>
                  </div>
                  </div>
            </nav>
                ) : (
        <nav className={ this.props.top ? "navbar fixed-top navbar-expand-md navbar-light bg-transparent" : "navbar fixed-top navbar-expand-md navbar-light bg-white"} 
        id={this.props.top? "head": null} >
              <Link className="navbar-brand" to="/">
                {this.state.matches &&(
                  <h2 class="naslov" style={{color: this.props.top ? 'white' : 'black', fontFamily: "Syncopate"}}><b>MEASURES HUB</b></h2>
                )}
                {!this.state.matches &&(
                  <z class="naslov" style={{color: this.props.top ? 'white' : 'black', fontFamily: "Syncopate"}}><b>MEASURES HUB</b></z>
                )}
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNavAltMarkup"
              >
      
                  <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/login" class="header_povezave">
                      <b style={{color: this.props.top ? 'white' : 'black'}}>LOGIN</b>
                    </Link>
                    <Link className="nav-item nav-link" to="/signup" class="header_povezave" >
                      <b style={{color: this.props.top ? 'white' : 'black'}}>REGISTER</b>
                    </Link>
                  </div>
                  </div>
            </nav>
                )}
          </header>
          )
      }
}


      /*</nav><nav className="navbar navbar-expand-md navbar-light bg-light">
      
       return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light">
        <Link className="navbar-brand" to="/">
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          {auth().currentUser ? (
            <div class="navbar-nav">
              <Link id="headerProfile" className="nav-item nav-link" to="/User"><FontAwesomeIcon icon={faUser} /> Profile
              </Link>
              <button
                className="btn btn-light"
                onClick={() => auth().signOut()}
              ><FontAwesomeIcon icon={faSignOutAlt}/> Logout
              </button>
            </div>
          ) : (
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/login">
                Sign In
              </Link>
              <Link className="nav-item nav-link" to="/signup">
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
      
      */