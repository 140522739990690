import React, { Component } from "react";
import { auth, database } from "../firebase";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user: auth().currentUser,
      phone: "",
      serial: "",
    };
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  render() {
    return (
      <form onSubmit={this.InputHandler}>
        <p>Enter phone number:</p>
        <input type="text" name="phone" onChange={this.myChangeHandler} />
        <p>Serial number::</p>
        <input type="text" name="serial" onChange={this.myChangeHandler} />
        <input type="submit" />
      </form>
    );
  }
}

export default Settings;
