import React, { Component, ListItem } from "react";
import { auth, db } from "../firebase";
import Header from "./Header";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";
import Card from "react-bootstrap/Card";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default class ChangeSerial extends Component {
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
      phone: "",
      alarm: "",
      devicename: "",
      report: "",
      numToAlarm: "",
      serials: [],
      last_temps: [],
      redirect: false,
      showForm: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createNote = this.createNote.bind(this);
    this.showForm = this.showForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    const uid = this.state.user.uid;
    const serialRef = db.ref(uid + "/serials");

    serialRef.on("value", (serial_numbers) => {
      const serials = [];
      serial_numbers.forEach((serial_number) => {
        console.log(serial_number.val());
        const za = serial_number.val();
        const ta = za.serial;
        //console.log(ta);
        const last_temps = [];
        const devicename = [];
        const nameRef = db.ref(ta + "/devicename");
        nameRef.on("value", (dname) => {
          devicename.push(dname.val());
          this.setState(devicename);
        });
        console.log(devicename);
        last_temps.push({ ta, d: devicename });
        console.log(last_temps);
        this.setState({ last_temps: last_temps });

        console.log(last_temps);
        serials.push({ s: last_temps });
        console.log(serials);
      });
      this.setState({ serials: serials });
    });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }

  handlePhoneChange = (value) => {
    console.log(value);
    this.setState({ phone: value }, () => {
      console.log(this.state.phone);
    });
  };

  showForm(value) {
    console.log("v show form");
    this.setState({ serial: value, showForm: true });
    const nameRef = db.ref(value + "/devicename");
    nameRef.on("value", (dname) => {
      this.setState({ devicename: dname.val() });
    });
    const phoneRef = db.ref(value + "/phone");
    phoneRef.on("value", (pname) => {
      this.setState({ phone: pname.val() });
    });
    const alarmRef = db.ref(value + "/alarm");
    alarmRef.on("value", (aname) => {
      this.setState({ alarm: aname.val() });
    });
    const reportRef = db.ref(value + "/report");
    reportRef.on("value", (rname) => {
      this.setState({ report: rname.val() });
    });
    const numToAlarmRef = db.ref(value + "/numToAlarm");
    numToAlarmRef.on("value", (nname) => {
      this.setState({ numToAlarm: nname.val() });
    });
  }

  createNote() {
    //e.preventDefault();
    const uid = this.state.user.uid;
    const serialRef = db.ref(uid + "/serials");
    const { serial } = this.state;
    serialRef
      .orderByChild("serial")
      .equalTo(serial)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const { phone } = this.state;
          const { alarm } = this.state;
          const { devicename } = this.state;
          const { report } = this.state;
          const { numToAlarm } = this.state;
          db.ref(`${serial}`)
            .set({
              phone,
              alarm,
              devicename,
              report,
              numToAlarm,
            })
            .then((_) => {
              this.setState(
                { serial: "" },
                { phone: "" },
                { alarm: "" },
                { devicename: "" },
                { report: "" },
                { numToAlarm: "" }
              );
            })
            .catch((error) => console.log(error.message));
        } else {
          alert("This serial doesn't exist!");
        }
      })
      .then(() => this.setState({ redirect: true }));
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/Profile" />;
    }
    return (
      <div class="wrapper">
        <Sidenav />
        <div id="content">
          {this.state.matches && <Header />}
          {!this.state.matches && <Navbar />}

          <div className="form-group mx-4">
            {this.state.matches && (
              <div className="container" style={{ width: "600px" }}>
                <Card>
                  <Card.Header style={{ backgroundColor: "#eceff1" }}>
                    <h1
                      style={{
                        marginTop: "7%",
                        marginLeft: "5%",
                        fontFamily: "Oswald",
                      }}
                    >
                      Change Serial
                    </h1>
                  </Card.Header>
                  <form
                    className="mt-5 py-5 px-5"
                    autoComplete="off"
                    onSubmit={this.createNote}
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button
                          class="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Choose device
                        </button>
                        <div class="dropdown-menu">
                          {this.state.serials.map((number) => {
                            return number.s && number.s.length ? (
                              <a
                                onClick={() => this.showForm(number.s[0].ta)}
                                class="dropdown-item"
                                href="#"
                              >
                                <b>{number.s[0].d[0]}</b>
                                <p>Serial:{number.s[0].ta}</p>
                              </a>
                            ) : null;
                          })}
                        </div>
                      </div>
                      <div style={{ width: "330px" }}>
                        <input
                          type="text"
                          class="form-control"
                          aria-label="Text input with dropdown button"
                          placeholder={this.state.serial}
                          value={this.state.serial}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Device name"
                        onChange={this.handleChange}
                        value={this.state.devicename}
                        style={{ width: "460px" }}
                        name="devicename"
                      />
                    </div>
                    {/* <div className="form-group">
              <input
                className="form-control"
                placeholder="Phone number"
                onChange={this.handleChange}
                value={this.state.phone}
                style={{width:'460px'}}
                name="phone"
              />
              </div> */}
                    <div className="form-group">
                      <PhoneInput
                        masks={{ si: ".. ... ... " }}
                        inputExtraProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        country={"si"}
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        inputStyle={{ width: "460px" }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Alarm temperature"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.alarm}
                        style={{ width: "460px" }}
                        name="alarm"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Report temperature"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.report}
                        style={{ width: "460px" }}
                        name="report"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Number of measurements to trigger alarm"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.numToAlarm}
                        style={{ width: "460px" }}
                        name="numToAlarm"
                      />
                    </div>
                    <button className="btn btn-primary rounded-pill px-5">
                      Submit
                    </button>
                  </form>
                </Card>
              </div>
            )}

            {!this.state.matches && (
              <div className="container">
                <Card>
                  <Card.Header style={{ backgroundColor: "#eceff1" }}>
                    <h1
                      style={{
                        marginTop: "7%",
                        marginLeft: "5%",
                        fontFamily: "Oswald",
                      }}
                    >
                      Change Serial
                    </h1>
                  </Card.Header>
                  <form
                    className="mt-5 py-5 px-5"
                    autoComplete="off"
                    onSubmit={this.createNote}
                  >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button
                          class="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Choose device
                        </button>
                        <div class="dropdown-menu">
                          {this.state.serials.map((number) => {
                            return number.s && number.s.length ? (
                              <a
                                onClick={() => this.showForm(number.s[0].ta)}
                                class="dropdown-item"
                                href="#"
                              >
                                <b>{number.s[0].d[0]}</b>
                                <p>Serial:{number.s[0].ta}</p>
                              </a>
                            ) : null;
                          })}
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          aria-label="Text input with dropdown button"
                          placeholder={this.state.serial}
                          value={this.state.serial}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Device name"
                        onChange={this.handleChange}
                        value={this.state.devicename}
                        name="devicename"
                      />
                    </div>
                    <div className="form-group">
                      <PhoneInput
                        masks={{ si: ".. ... ... " }}
                        inputExtraProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        country={"si"}
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        inputStyle={{ width: "auto" }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Alarm temperature"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.alarm}
                        name="alarm"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Report temperature"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.report}
                        name="report"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        placeholder="Number of measurements to trigger alarm"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.numToAlarm}
                        name="numToAlarm"
                      />
                    </div>
                    <button className="btn btn-primary rounded-pill px-5">
                      Submit
                    </button>
                  </form>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

{
  /* <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Device Name
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    {this.state.serials.map((number) => {
    return number.s && number.s.length? (
    <a onClick={()=>this.showForm(number.s[0].ta)} class="dropdown-item" href="#">
      <b>{number.s[0].d[0]}</b> 
      <p>Serial:{number.s[0].ta}</p>
    
    </a>
  ) : null
})}
</div>
</div> */
}
