import React from "react";
import { database } from "../firebase";
import { signup } from "../auth.js";
import { auth } from "../firebase";
import { Link, Redirect, withRouter } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import ScrollAnimation from 'react-animate-on-scroll';
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";

// var actionCodeSettings = {
//   // URL you want to redirect back to. The domain (www.example.com) for this
//   // URL must be in the authorized domains list in the Firebase Console.
//   url: 'www.measureshub.com',
//   // This must be true.
//   handleCodeInApp: true,
//   iOS: {
//     bundleId: 'com.example.ios'
//   },
//   android: {
//     packageName: 'com.example.android',
//     installApp: true,
//     minimumVersion: '12'
//   },
//   dynamicLinkDomain: 'example.page.link'
// };

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      password: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
  }


  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  mySubmitHandler = (event) => {
    event.preventDefault();
    alert("You are submitting " + this.state.email);
    signup(this.state.email, this.state.password);
  };

  // mySubmitHandler = (event) => {
  //   event.preventDefault();
  //   auth().sendSignInLinkToEmail(this.state.email, actionCodeSettings)
  //   .then(() => {
  //     // The link was successfully sent. Inform the user.
  //     // Save the email locally so you don't need to ask the user for it again
  //     // if they open the link on the same device.
  //     window.localStorage.setItem('emailForSignIn', this.state.email);
  //   })
  //   .catch((error) => {
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //   });
  // };

  render() {
    return (
<div class="login_p">
{this.state.matches &&(
  <ScrollAnimation animateIn="fadeIn">
      <div className="container" style={{width:'555px'}}>
      <Card style={{marginTop: '30%', backgroundColor: 'rgba(65, 65, 65, 0.8)'}}>
        <form
        className="mt-5 py-5 px-5"
        autoComplete="off"
         onSubmit={this.mySubmitHandler}>
           <h1 style={{color: 'white', textAlign:"center"}}>Register</h1>
          <p className="lead">
            Fill in the form below to create a new account.
          </p>
          <div className="form-group">
          <input
              className="form-control"
              placeholder="Email"
              name="email"
              type="email"
              onChange={this.myChangeHandler}
            ></input>
            </div>
            <div className="form-group">
         <input
              className="form-control"
              placeholder="Password"
              name="password"
              onChange={this.myChangeHandler}
              type="password"
            ></input>
            </div>
            <div className="form-group">
            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}
            <button className="btn btn-primary rounded-pill px-5">Sign up</button>
          </div>
        </form>
        </Card>
      </div>
      </ScrollAnimation>
)}
{!this.state.matches &&(
  <ScrollAnimation animateIn="fadeIn">
  <div className="container" >
  <Card style={{marginTop: '30%', backgroundColor: 'rgba(65, 65, 65, 0.8)'}}>
    <form
    className="mt-5 py-5 px-5"
    autoComplete="off"
     onSubmit={this.mySubmitHandler}>
       <h1 style={{color: 'white', textAlign:"center"}}>Register</h1>
      <p className="lead">
        Fill in the form below to create a new account.
      </p>
      <div className="form-group">
      <input
          className="form-control"
          placeholder="Email"
          name="email"
          type="email"
          onChange={this.myChangeHandler}
        ></input>
        </div>
        <div className="form-group">
     <input
          className="form-control"
          placeholder="Password"
          name="password"
          onChange={this.myChangeHandler}
          type="password"
        ></input>
        </div>
        <div className="form-group">
        {this.state.error ? (
          <p className="text-danger">{this.state.error}</p>
        ) : null}
        <button className="btn btn-primary rounded-pill px-5">Sign up</button>
      </div>
    </form>
    </Card>
  </div>
  </ScrollAnimation>
)}
      </div>

    );
  }
}

export default withRouter(SignUp);
