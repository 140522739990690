import React, { Component, useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { auth, db } from "../firebase";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import "../App.css";
import * as ReactBootStrap from "react-bootstrap";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";
export const columns = [
  {
    dataField: "timestamp",
    text: "Timestamp",
  },
  {
    dataField: "temperature",
    text: "Temperature",
  }
];

class ShowTable extends Component {
  constructor(props) {
    super(props);
    this.state = { timestamps: [], 
      user: auth().currentUser, 
      serial: "", 
      devicenames: [],
      serials: [],
      last_temps: [],  };
  }

  componentDidMount() {
    const uid = this.state.user.uid;
    const serialRef = db.ref(uid + '/serials');
  
    serialRef.on('value', (serial_numbers) => {
      const serials = [];
      serial_numbers.forEach((serial_number) => {
        console.log(serial_number.val());
        const za = serial_number.val();
        const ta = za.serial;
        //console.log(ta);
        const last_temps = [];
        const devicename = [];
        const nameRef = db.ref(ta + '/devicename');
        nameRef.on('value', (dname)=> {
          devicename.push(dname.val());
          this.setState(devicename );
        })
        const tempRef = db.ref('Devices/' + ta);
        tempRef.on('value', (temps) => {
          const temparray = [];
          temps.forEach((temp) => {
            //console.log(temp.val());
            temparray.push(temp.val());
            console.log(temparray);
          })
          const last_temp = temparray[temparray.length-2];
          //console.log(last_temp[0]);
          
          console.log(devicename)
          last_temps.push({ l: last_temp[0], ta, d: devicename });
          console.log(last_temps);
          this.setState({ last_temps: last_temps })
        })
        console.log(last_temps);
        serials.push({ s: last_temps });
        console.log(serials);
      });
      this.setState({ serials: serials });
    });
  }


  render() {
    return (
      <div>
        <div class="dropdown">
  <button onClick={this.come} class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Dropdown button
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    {console.log(this.state.devicenames)}
    {this.state.serials.map((number) => {
    return number.s && number.s.length? (
      <a class="dropdown-item" href="#">{number.s[0].d[0]}</a>
  ) : null
})}
  </div>
</div>
        </div>
    );
  }
}

export default ShowTable;

/*
<a  href="#Subme" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Devices</a>
                <ul class="collapse list-unstyled" id="Subme" >
                {this.state.devicenames.map((number) => ( 
                <li>
                        <a onClick={this.getUserSerials}>{number}</a>
                    </li>
                    ))}
                    </ul>
*/
