import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase";

export default class Payement extends Component {
    constructor() {
        super();
        this.state = {
          error: null,
          serial: "",
          payement: "",
          number: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.createNote = this.createNote.bind(this);
        this.polishNumber = this.polishNumber.bind(this);
      }

    handleChange(event) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }

      createNote(e) {
        e.preventDefault();
        const { serial } = this.state;
        const {payement} = this.state; 
        db.ref("payement/" + serial).set({
            payement,
          })
          .then((_) => {
            this.setState({payement: ""});
          })
      } 

      polishNumber(e){
        e.preventDefault();
        const {number} = this.state;
        console.log({number})
        db.ref("number/").set({
          number,
        })
        .then((_) => {
          this.setState({number: ""});
        })
      }

render(){
    return(
<div>
<form
          className="mt-5 py-5 px-5"
          autoComplete="off"
          onSubmit={this.createNote}
        >
          <h1>Add paid device</h1>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Serial"
              name="serial"
              type="serial"
              onChange={this.handleChange}
              value={this.state.serial}
            ></input>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Paid =1, not = 0"
              name="payement"
              type="payement"
              onChange={this.handleChange}
              value={this.state.payement}
            ></input>
          </div>
          <button className="btn btn-primary rounded-pill px-5">Submit</button>
</form>
<form  className="mt-5 py-5 px-5"
          autoComplete="off"
          onSubmit={this.polishNumber}>
<div className="form-group">
<h1>Change polishNumber</h1>
            <input
              className="form-control"
              placeholder="Polish number"
              name="number"
              type="polishnumber"
              onChange={this.handleChange}
              value={this.state.number}
            ></input>
          </div>
          <button className="btn btn-primary rounded-pill px-5">Submit</button>
</form>


</div>
    )
}
}
