import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { signin } from "../auth";
import Card from 'react-bootstrap/Card';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: "",
      password: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await signin(this.state.email, this.state.password);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }


  render() {
    return (
      <div class="login_p">
         {this.state.matches &&(<ScrollAnimation animateIn="fadeIn">
      <div className='container' style={{width:'555px'}}>
        <Card style={{marginTop: '30%', backgroundColor: 'rgba(65, 65, 65, 0.8)'}}>
        <form
          className="py-5 px-5"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        > 
          <h1 style={{color: 'white', textAlign:"center"}}>Log In</h1>
          <p className="lead" style={{textAlign:"center"}}>
            Fill in the form below to login to your account.
          </p>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Email"
              name="email"
              type="email"
              onChange={this.handleChange}
              value={this.state.email}
              style={{width:'430px'}}
            ></input>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              name="password"
              onChange={this.handleChange}
              value={this.state.password}
              style={{width:'430px'}}
              type="password"
            ></input>
          </div>
          <div className="form-group">
            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}
            <button className="btn btn-primary rounded-pill px-5">Login</button>
          </div>
          <hr></hr>
          <p>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
          <p>
          <Link to="/ForgotPassword">Forgot password?</Link>
          </p>
        </form>
        </Card>
      </div>
      </ScrollAnimation>)}
      {!this.state.matches && (
        <ScrollAnimation animateIn="fadeIn">
        <div className='container' >
          <Card style={{marginTop: '30%', backgroundColor: 'rgba(65, 65, 65, 0.8)'}}>
          <form
            className="py-5 px-5"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <h1 style={{color: 'white', textAlign:"center"}}>Log In</h1>
            <p className="lead" style={{textAlign:"center"}}>
              Fill in the form below to login to your account.
            </p>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Email"
                name="email"
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
              ></input>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Password"
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
                type="password"
              ></input>
            </div>
            <div className="form-group">
              {this.state.error ? (
                <p className="text-danger">{this.state.error}</p>
              ) : null}
              <button className="btn btn-primary rounded-pill px-5">Login</button>
            </div>
            <hr></hr>
            <p>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
            <p>
            <Link to="/ForgotPassword">Forgot password?</Link>
            </p>
          </form>
          </Card>
        </div>
        </ScrollAnimation>
      )}
      </div>
    );
  }
}
