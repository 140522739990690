import React, { Component, useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { auth, db } from "../firebase";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import XLSX from 'xlsx'
import "../App.css";
import * as ReactBootStrap from "react-bootstrap";
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import Header from "./Header";
import "./Sidebar.css";
export const columns = [
  {
    dataField: "timestamp",
    text: "Timestamp",
  },
  {
    dataField: "temperature",
    text: "Temperature",
  }
];

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = { timestamps: [], 
      user: auth().currentUser, 
      serial: "", 
      months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    m: "",
    getSerial: "",
    devicename: "",
    report: "",
    matches: window.matchMedia("(min-width: 768px)").matches  };
    this.generatePdf = this.generatePdf.bind(this);
    this.pickMonth = this.pickMonth.bind(this);
    this.exportFile = this.exportFile.bind(this)
  }

  componentDidMount() {
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(min-width: 768px)").addListener(handler);
      console.log(this.state.matches)
      const path = window.location.pathname.replace('/table/', '')
    this.setState({getSerial: path})
    const uid = this.state.user.uid;
    const nameRef = db.ref(path + '/devicename');
    nameRef.once('value', (dname)=> {
      this.setState({devicename: dname.val() });
    })
    const reportRef = db.ref(path + '/report');
    reportRef.once('value', (reportTemp)=> {
      this.setState({report: reportTemp.val() });
    })
        const database = db.ref().child("Devices/" + this.props.location.state.serial);
        database.orderByKey().limitToLast(1488).on("value", (ts_measures) => {
            const timestamps = [];
            ts_measures.forEach((ts_measure) => {
                var a = new Date(ts_measure.val()['1']);
                var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var year = a.getFullYear();
                var month = months[a.getMonth()];
                var date = a.getDate();
                var hour = a.getHours();
                var min = a.getMinutes();
                var sec = a.getSeconds();
                var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
                var temp = ts_measure.val()['0'];
                timestamps.push({ timestamp: time, temperature: temp });
            });
          timestamps.reverse();
          this.setState({ timestamps: timestamps });
        });
}


componentDidUpdate(prevProps) {
  const path = window.location.pathname.replace('/table/', '')
  
  if(path != this.state.getSerial){
    //prevProps.location.state.serial
    const uid = this.state.user.uid;
    const nameRef = db.ref(path + '/devicename');
    nameRef.once('value', (dname)=> {
      this.setState({devicename: dname.val() });
    })
    const reportRef = db.ref(path + '/report');
    reportRef.once('value', (reportTemp)=> {
      this.setState({report: reportTemp.val() });
    })
    const database = db.ref().child("Devices/" + path);
    database.orderByKey().limitToLast(1488).on("value", (ts_measures) => {
        const timestamps = [];
        ts_measures.forEach((ts_measure) => {
            var a = new Date(ts_measure.val()['1']);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            var temp = ts_measure.val()['0'];
            timestamps.push({ timestamp: time, temperature: temp });
        });
        timestamps.reverse();
        this.setState({ timestamps: timestamps }); 
    });
    this.setState({getSerial: path})
}
}



pickMonth=(value)=>{
  this.setState({ m: value }, ()=> this.generatePdf(value))
}

generatePdf(value){
    const path = window.location.pathname.replace('/table/', '')
    const uid = this.state.user.uid;
    const devicename = this.state.devicename;
    console.log(this.state.devicename)
    const report = this.state.report;
    // console.log("Report temp je: " + this.state.report)
    const database = db.ref().child("Devices/" + path);
    database.once("value", (ts_measures) => {
      const timestamps = [];
      let prevHour = null;

      ts_measures.forEach((ts_measure) => {
        var a = new Date(ts_measure.val()['1']);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = year + '-' + month + '-' + date + ' ' + hour + ':' + min + ':' + sec;
        var temp = ts_measure.val()['0'];

        //V PDF dodaj samo dve meritvi na dan - ZAKAJ NALOŽIMO VSE MERITVE, ČE ŽELIMO SAMO EN MESEC?! - zato, ker obstaja samo še funkcija limitToLast(), 
        //vendar je ne moremo uporabit, ker ne vemo kateri mesec želi uporabnik - zato naložimo vse podatke ---------------------------------------------
        if (hour !== prevHour) {
          if(hour === 8 || hour === 16) {
            if(temp > report && report != null && report != "") {
              // console.log("Report: " + report)
              timestamps.push({ timestamp: time, temperature: Number(report).toFixed(2) });
              prevHour = hour; // Switch to the next expected hour
            } else {
              timestamps.push({ timestamp: time, temperature: temp.toFixed(2) });
              prevHour = hour; // Switch to the next expected hour
            }
          }
          if((hour < 16 && hour > 8) && (prevHour === 16 || prevHour === null)) { //Pošlji naslednjo meritev, če manjka meritev ob 8ih; prevHour === null je za primer, če je prvi podatek po 16ih in bi zato tisti dan bil izpuščen iz poročila
            console.log("Pošiljam: " +time+ temp)
            if(temp > report && report != null && report != "") {
              timestamps.push({ timestamp: time, temperature: Number(report).toFixed(2) });
              prevHour = 8; // Switch to the next expected hour
            } else {
              timestamps.push({ timestamp: time, temperature: temp.toFixed(2) });
              prevHour = 8; // Switch to the next expected hour
            }
          }
          if((hour > 16 || hour < 8) && (prevHour === 8 || prevHour === null)) { //Pošlji naslednjo meritev, če manjka meritev ob 16ih
            console.log("Pošiljam2: " + temp)
            if(temp > report && report != null && report != "") {
              timestamps.push({ timestamp: time, temperature: Number(report).toFixed(2) });
              prevHour = 16; // Switch to the next expected hour
            } else {
              timestamps.push({ timestamp: time, temperature: temp.toFixed(2) });
              prevHour = 16; // Switch to the next expected hour
            }
          }
          // console.log(timestamps)
        }
        //----------------------------------------------------------------------------------------------------------------
      });
      timestamps.reverse();
      
      //ODSTRANI PRVI ELEMENT IZ PDF REPORTA -----------------------------------------------------------------------------
      // let removed = timestamps.splice(0,1);
      //------------------------------------------------------------------------------------------------------------------

      let groupKey = "";
      const groups = timestamps.reduce(function (r, o) {
          var m = o.timestamp.split(('-'))[1];
          (r[m])? r[m].data.push(o) : r[m] = {group: String(groupKey=m), data: [o]};
          return r;
      }, {});
      var result = Object.keys(groups).map(function(k){ return groups[k]; });
      for (var i = 0; i < result.length; i++){
        if ( result[i].group == value){
            const data = result[i].data.map(elt => [elt.timestamp, elt.temperature])
            const headers = [["Timestamp", "Temperature"]];
            let content = {
                startY: 50,
                head: headers,
                body: data,
            };
            var serial = "serial no."
            let text =[
                devicename,
                serial + path,
            ]
            const doc = new jsPDF();
            doc.text(text, 10, 30);
            doc.autoTable(content);
            console.log(devicename)
            doc.save("report"+ devicename +".pdf");
        }
      }
    });
}

exportFile(value) {
    const path = window.location.pathname.replace('/table/', '')
    const uid = this.state.user.uid;
    const devicename = this.state.devicename;
    console.log(this.state.devicename)
    const database = db.ref().child("Devices/"+ path);
    database.once("value", (ts_measures) => {
        const timestamps = [];
        ts_measures.forEach((ts_measure) => {
            var a = new Date(ts_measure.val()['1']);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = year + '-' + month + '-' + date + ' ' + hour + ':' + min + ':' + sec ;

            var temp = ts_measure.val()['0'];
            timestamps.push({ timestamp: time, temperature: temp });
        });
        let removed = timestamps.splice(0,1);
                
        let groupKey = "";
        const groups = timestamps.reduce(function (r, o) {
            var m = o.timestamp.split(('-'))[1];
            (r[m])? r[m].data.push(o) : r[m] = {group: String(groupKey=m), data: [o]};
            return r;
        }, {});
        var result = Object.keys(groups).map(function(k){ return groups[k]; });
        for (var i = 0; i < result.length; i++){
            if ( result[i].group == value){
                let data = result[i].data.map(elt => [elt.timestamp, elt.temperature])
                // const headers = [["Timestamp", "Temperature"]];
                // let content = {
                //   startY: 50,
                //   head: headers,
                //   body: data,
                // };
                // var serial = "serial no."
                // let text =[
                //   devicename,
                //   serial + path,
                // ]
                const wb = XLSX.utils.book_new()
                const wsAll = XLSX.utils.aoa_to_sheet(data)
                
                XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
                XLSX.writeFile(wb, "export-demo.xlsx")
            }
        }
    });
}


    render() {
        return (
            <div class="wrapper">
                <Sidenav/>
                <div id="content">
                    {this.state.matches && (<Header/>)}
                    {!this.state.matches && (<Navbar />)}
                    <div class="space">
                        <h3>{this.state.devicename}</h3>
                        <h5>{this.state.getSerial}</h5>
                        <BootstrapTable
                        keyField="timestamp"
                        data={this.state.timestamps}
                        columns={columns}
                        pagination={paginationFactory()}
                        />
                        <div class="dropdown show" style={{paddingBottom:"1%"}}>
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Generate Pdf
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {this.state.months.map((month) => (
                                    <a onClick={()=>this.pickMonth(month)} class="dropdown-item" href="#">{month}</a>
                                ))}
                            </div>
                        </div>
                        <div class="dropdown show" style={{paddingBottom:"1%"}}>
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Generate Excel
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {this.state.months.map((month) => (
                                    <a onClick={()=>this.exportFile(month)} class="dropdown-item" href="#">{month}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Table;

/*
<BootstrapTable
          keyField="timestamp"
          data={this.state.timestamps}
          columns={columns}
          pagination={paginationFactory()}
        />

        <a onClick={} m="Jan" class="dropdown-item" href="#">January</a>
    <a m="Feb" class="dropdown-item" href="#">February</a>
    <a m="Mar" class="dropdown-item" href="#">March</a>
    <a m="Apr" class="dropdown-item" href="#">April</a>
    <a m="May" class="dropdown-item" href="#">May</a>
    <a m="Jun" class="dropdown-item" href="#">June</a>
    <a m="Jul" class="dropdown-item" href="#">July</a>
    <a m="Aug" class="dropdown-item" href="#">August</a>
    <a m="Sep" class="dropdown-item" href="#">September</a>
    <a m="Oct" class="dropdown-item" href="#">October</a>
    <a m="Nov" class="dropdown-item" href="#">November</a>
    <a m="Dec" class="dropdown-item" href="#">December</a>


<div class="dropdown show">
        <ul class="list-unstyled components">
            <li>
            <a  href="#Dropdown" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Choose month</a>
                <ul class="collapse list-unstyled" id="Dropdown" >
                {this.state.months.map((month) => ( 
                <li>
                        <a>{month}</a>
                    </li>
                    ))};
                </ul>
                </li>
                </ul>
          </div>
    */

    /*
    componentDidUpdate(prevProps) {
    console.log(this.state.getSerial)
    if (this.props.location?.state?.serial == undefined){
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(min-width: 768px)").addListener(handler);
      console.log(this.state.matches)
  const uid = this.state.user.uid;
  console.log(uid);
  
      const database = db.ref().child("Devices/" + this.state.getSerial);
      database.once("value", (ts_measures) => {
        const timestamps = [];
    ts_measures.forEach((ts_measure) => {
      var a = new Date(ts_measure.val()['1']);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  console.log(time);
  var temp = ts_measure.val()['0'];
  console.log(temp);
  timestamps.push({ timestamp: time, temperature: temp });
        });
        timestamps.reverse();
        let removed = timestamps.splice(0,1);
        console.log(timestamps);
        this.setState({ timestamps: timestamps });
       
      });
    }
    
    else{
      //console.log(prevProps.location.state.serial)
      if(this.props.location.state.serial != prevProps.location.state.serial){
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
        console.log(this.state.matches)
    const uid = this.state.user.uid;
    console.log(uid);
    
        const database = db.ref().child("Devices/" + this.props.location.state.serial);
        database.once("value", (ts_measures) => {
          const timestamps = [];
      ts_measures.forEach((ts_measure) => {
        var a = new Date(ts_measure.val()['1']);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    console.log(time);
    var temp = ts_measure.val()['0'];
    console.log(temp);
    timestamps.push({ timestamp: time, temperature: temp });
          });
          timestamps.reverse();
          let removed = timestamps.splice(0,1);
          console.log(timestamps);
          this.setState({ timestamps: timestamps });
         
        });
      }
  }
  
}
*/