import React, { Component, useState } from 'react';
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase";
import { Redirect } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default class Navbar extends Component{
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
          serials: [],
          timestamps:[],
          showComponent: false,
          empty: false, 
          rotate: true,
        matches: window.matchMedia("(min-width: 768px)").matches
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.rotate = this.rotate.bind(this);
  }

    componentDidMount() {
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(min-width: 768px)").addListener(handler);
      const uid = this.state.user.uid;
    const serialRef = db.ref(uid + '/serials');
    
    serialRef.on('value', (serial_numbers) => {
    const serials = [];
    let empty = true;
    serial_numbers.forEach((serial_number) => {
      console.log(serial_number.val());
      const za = serial_number.val();
      const ta = za.serial;
      //console.log(ta);
      const last_temps = [];
      const devicename = [];
      const nameRef = db.ref(ta + '/devicename');
      nameRef.on('value', (dname)=> {
        devicename.push(dname.val());
        this.setState(devicename );
      })
        console.log(devicename)
        last_temps.push({ ta, d: devicename });
        console.log(last_temps);
        this.setState({ last_temps: last_temps })
      
      console.log(last_temps);
      serials.push({ s: last_temps });
      if (serials.length == 0){
              empty = true
              }
              else {
                empty = false
              }
    });
    this.setState({ serials: serials, empty: empty });
    });
    }
    

handleSelect=(value)=>{
  this.setState( {serial: value, showComponent: true}, ()=>console.log(this.state.serial))
  }

  rotate(){
    const rotate = this.state.rotate;
    if (rotate == true){
    this.setState({rotate: false})
    }
    else{
      this.setState({rotate: true})
    }
  }

render(){
return(
  <header>
      <nav id="navhead" className="navbar navbar-expand-md navbar-light bg-light">
        <Link className="navbar-brand" to="/"> <z class="naslov" style={{fontFamily: "Syncopate"}}><b>MEASURES HUB</b></z>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          {auth().currentUser ? (
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/profile">
                Dashboard
              </Link>
                <c  onClick={this.rotate} href="#Sub" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Tables {this.state.rotate? <FontAwesomeIcon icon={faChevronRight} rotation={0}/> : <FontAwesomeIcon icon={faChevronRight} rotation={90}/>}</c>
                <ul class="collapse list-unstyled" id="Sub" >
                {this.state.empty ?
                 <li>
                 <a >You have no serials</a>
             </li>
             :null 
             }
                {this.state.serials.map((number) => ( 
                <li>
                        <a onClick={()=>this.handleSelect(number.s[0].ta)}>{number.s[0].ta} - {number.s[0].d[0]}</a>
                    </li>
                    ))}
                    {this.state.showComponent ? 
<Redirect to={{ pathname: '/table/'+this.state.serial,
                state: {serial: this.state.serial}
                }}
/>:
null 
}
                </ul>
                <li>
                <Link to="/ChangeSettings">Add serial</Link>
                </li>
                <li>
                <Link to="/ChangeSerial">Change serial</Link>
                </li>
                {/* <li>
                <Link to="/Calibration">Calibrate</Link>
                </li> */}
                <button
                className="btn btn-primary"
                onClick={() => auth().signOut()}
              >
                Logout
              </button>
            </div>
          ) : (
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/login">
                Sign In
              </Link>
              <Link className="nav-item nav-link" to="/signup">
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </nav>
    </header>
);
          }
};
     

        /*
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">

                <button 
                type="button" 
                id="sidebarCollapse" 
                class="btn btn-info">
                    <i class="fas fa-align-left"></i>
                    <span>Toggle Sidebar</span>
                </button>
                <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-align-justify"></i>
                </button>

                <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          {auth().currentUser ? (
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/profile">
                Profile
              </Link>
              <button
                className="btn btn-primary"
                onClick={() => auth().signOut()}
              >
                Logout
              </button>
            </div>
          ) : (
            <div className="navbar-nav">
              <Link className="nav-item nav-link" to="/login">
                Sign In
              </Link>
              <Link className="nav-item nav-link" to="/signup">
                Sign Up
              </Link>
            </div>
          )}
        </div>
            </div>
        </nav>
        */