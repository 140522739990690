import React, { Component, useState } from 'react';
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase";
import { Redirect } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faChevronRight, faCog, faPlusSquare, faTable, faHdd } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {faplussquare} from '@fortawesome/free-solid-svg-icons'

export default class Sidenav extends Component{
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
      phone: "",
          alarm: "",
          devicename: "",
          last_temps: [],
          serials: [],
          timestamps:[],
          showComponent: false,
          empty: false, 
          rotate: true,
        matches: window.matchMedia("(min-width: 768px)").matches
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.rotate = this.rotate.bind(this);
  }

//   componentDidMount() {
//     const handler = e => this.setState({matches: e.matches});
//     window.matchMedia("(min-width: 768px)").addListener(handler);
//     const uid = this.state.user.uid;
//     const serialRef = db.ref(uid + '/serials');
  
//     serialRef.on('value', (serial_numbers) => {
//       const serials = [];
//       let empty = true;
//       serial_numbers.forEach((serial_number) => {
//         console.log(serial_number.val());
//         const za = serial_number.val();
//         const ta = za.serial;
//         console.log(ta);
//     serials.push({ ta });
    
//     if (serials.length == 0){
//       empty = true
//       }
//       else {
//         empty = false
//       }
// });
// this.setState({ serials: serials, empty: empty });
// });
// }

componentDidMount() {
  const handler = e => this.setState({matches: e.matches});
  window.matchMedia("(min-width: 768px)").addListener(handler);
  const uid = this.state.user.uid;
 
const serialRef = db.ref(uid + '/serials');

serialRef.on('value', (serial_numbers) => {
const serials = [];
let empty = true;
serial_numbers.forEach((serial_number) => {
  console.log(serial_number.val());
  const za = serial_number.val();
  const ta = za.serial;
  //console.log(ta);
  const last_temps = [];
  const devicename = [];
  const nameRef = db.ref(ta + '/devicename');
  nameRef.on('value', (dname)=> {
    devicename.push(dname.val());
    this.setState(devicename );
  })
    console.log(devicename)
    last_temps.push({ ta, d: devicename });
    console.log(last_temps);
    this.setState({ last_temps: last_temps })
  
  console.log(last_temps);
  serials.push({ s: last_temps });
  if (serials.length == 0){
          empty = true
          }
          else {
            empty = false
          }
});
this.setState({ serials: serials, empty: empty });
});
}


handleSelect=(value)=>{
  this.setState( {serial: value, showComponent: true}, ()=>console.log(this.state.serial))
  }

rotate(){
  const rotate = this.state.rotate;
  if (rotate == true){
  this.setState({rotate: false})
  }
  else{
    this.setState({rotate: true})
  }
}

render(){
  return (
    <nav id="sidebar">
        <div class="sidebar-header">
            <Link to="/"><h3 style={{fontFamily: "Syncopate"}}>MEASURES HUB</h3></Link>
        </div>

        <ul class="list-unstyled components">
          <li>
          <Link className="nav-item nav-link" to="/profile"><FontAwesomeIcon icon={faHome}/> Dashboard
              </Link>
          </li>
            <li>
            <a  onClick={this.rotate} href="#Submen" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><FontAwesomeIcon icon={faTable}/> Tables {this.state.rotate? <FontAwesomeIcon icon={faChevronRight} rotation={0}/> : <FontAwesomeIcon icon={faChevronRight} rotation={90}/>}</a>
                <ul class="collapse list-unstyled" id="Submen" >
                {this.state.empty ?
                 <li>
                 <a >You have no serials</a>
             </li>
             :null 
             }
                {this.state.serials.map((number) => ( 
                <li>
                        <a onClick={()=>this.handleSelect(number.s[0].ta)}>{number.s[0].ta} - {number.s[0].d[0]}</a>
                    </li>
                    ))}
                    {this.state.showComponent ?
<Redirect to={{ pathname: '/table/'+this.state.serial,
                state: {serial: this.state.serial}
                }}
/>:
null 
}
                </ul>
                <li>
                <Link to="/ChangeSettings"><FontAwesomeIcon icon={faPlusSquare}/> Add Serial</Link>
                </li>
                <li>
                <Link to="/ChangeSerial"><FontAwesomeIcon icon={faCog}/> Change Serial</Link>
                </li>
                {/* <li>
                  <Link to="/Calibration"><FontAwesomeIcon icon={faBullseye}/> Calibrate</Link> 
                </li>
                <li>
                  <Link to="/AddGateway"><FontAwesomeIcon icon={faHdd}/> Add Gateway</Link> 
                </li>
                <li>
                  <Link to="/GatewayManager"><FontAwesomeIcon icon={faHdd}/> Gateway Manager</Link> 
                </li> */}
            </li>
        </ul>
    </nav> 
  );
}
}



/*
import React from 'react'
import { Sidebar, SidebarItem } from 'react-responsive-sidebar';

function Sidenav(){
  const items = [
    <SidebarItem>Dashboard</SidebarItem>,
    <SidebarItem>Profile</SidebarItem>,
    <SidebarItem>Settings</SidebarItem>,
  ];

  return( 
    <Sidebar bg="black" content={items}>
  </Sidebar>
)
}
export default Sidenav;


import {Sidebar, InputItem, DropdownItem, Icon, Item, Logo, LogoText} from 'react-sidebar-ui'
import 'react-sidebar-ui/dist/index.css';
import React, { Component } from "react";


function Sidenav() {
    return (
<Sidebar bgColor='black' isCollapsed={false}>
<Logo
  image='https://media2.giphy.com/media/eNAsjO55tPbgaor7ma/source.gif'
  imageName='react logo'/>
<LogoText>React Sidebar UI</LogoText>
<DropdownItem
  values={['First', 'Second', 'Third']}
  bgColor={'black'}>
  Menu
</DropdownItem>

<Item bgColor='black'>
  <Icon><i className="fas fa-home"/></Icon>
  Home
</Item>
<Item bgColor='black'>
  <Icon><i className="fas fa-info"/></Icon>
  About
</Item>
<Item bgColor='black'>
  <Icon><i className="fas fa-sitemap"/></Icon>
  My Website
</Item>
<Item bgColor='black'>
  <Icon><i className="far fa-address-book"/></Icon>
  Contacts
</Item>
<Item bgColor='black'>
  <Icon><i className="fas fa-rss-square"/></Icon>
  Blog
</Item>
<InputItem type='text' placeholder={'Search...'}/>
</Sidebar>
);
}

export default Sidenav
*/
