import React, { Component, ListItem } from "react";
import { auth, db } from "../firebase";
import Header from "./Header";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default class ChangeSerial extends Component {
    constructor() {
        super();
        this.state = {
          user: auth().currentUser,
          serial: "",
          temp1: "",
          temp2: "",
          metemp1: "",
          metemp2:"",
          serials:[],
          last_temps: [],
          last_one:"",
          redirect: false,
          showForm: false,
          showForm2: false,
          showdropdown: true,
          matches: window.matchMedia("(min-width: 768px)").matches
        };
        this.startCalibration = this.startCalibration.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.createNote = this.createNote.bind(this);
        this.createSecond = this.createSecond.bind(this);
        this.showForm = this.showForm.bind(this);
        this.endCalibration = this.endCalibration.bind(this);
      }

      componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
        const uid = this.state.user.uid;
    const serialRef = db.ref(uid + '/serials');
  
    serialRef.on('value', (serial_numbers) => {
      const serials = [];
      serial_numbers.forEach((serial_number) => {
        console.log(serial_number.val());
        const za = serial_number.val();
        const ta = za.serial;
        //console.log(ta);
        const last_temps = [];
        const devicename = [];
        const nameRef = db.ref(ta + '/devicename');
        nameRef.on('value', (dname)=> {
          devicename.push(dname.val());
        })
          console.log(devicename)
          last_temps.push({ ta, d: devicename });
          console.log(last_temps);
        
        console.log(last_temps);
        serials.push({ s: last_temps });
        console.log(serials);
      });
      this.setState({ serials: serials });
    });
      }


      showForm (value){
        this.setState({serial: value})
        const serials = [];
        let last_one = "";
        const tempRef = db.ref('Devices/' + value);
        tempRef.orderByKey().limitToLast(1).on('value', (temps) => {
          const temparray = [];
          temps.forEach((temp) => {
            temparray.push(temp.val());
            console.log(temp.val());
          })
          last_one = temparray[0][0];
          console.log(last_one);
          this.setState({ last_one: temparray[0][0] });
        })
      }


startCalibration(){
    db.ref("Calibration/" + this.state.serial + "/start").set("1")
    this.setState({showForm: true, showdropdown: false})
}

endCalibration(){
  db.ref("Calibration/" + this.state.serial + "/start").set("0")
    this.setState({redirect: true})
}

handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }

createNote(e) {
  e.preventDefault()
    //this.state.last_temps.forEach((temp) => {
      this.setState({temp1: this.state.last_one, showForm2: true, showForm: false})
    
    // const RawRange = temp1 - temp2
    // const ReferenceRange = metemp1 - metemp2
    // const CorrectedValue = (((RawValue - temp2) * ReferenceRange) / RawRange) + temp2;
  }

  createSecond(e) {
      const {temp1} = this.state;
      const {metemp1} = this.state;
      const {temp2} = this.state;
      const {metemp2} = this.state;
      //this.state.last_temps.forEach((temp) => {
      db.ref("Calibration/" + this.state.serial).set({
        temp1,
        metemp1,
          temp2: this.state.last_one,
          metemp2,
          start: "0",
          exists: "1",
      //})
        })
        this.setState({redirect: true})
      // const RawRange = temp1 - temp2
      // const ReferenceRange = metemp1 - metemp2
      // const CorrectedValue = (((RawValue - temp2) * ReferenceRange) / RawRange) + temp2;
    }

      render() {
        const { redirect } = this.state;

        if (redirect) {
          return <Redirect to='/Profile'/>;
        }
        return (
          <div class="wrapper">
             <Sidenav/>
             <div id="content">
             {this.state.matches && (<Header/>)}
      {!this.state.matches && (<Navbar />)}

<div class="space">
  <h1>Calibration</h1>
      <div class="input-group mb-3">
      {this.state.showdropdown ?
  <div class="input-group-prepend">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Choose serial
      <FontAwesomeIcon icon={faChevronRight} />
      </button>
    <div class="dropdown-menu">
    {this.state.serials.map((number) => {
    return number.s && number.s.length? (
    <a onClick={()=>this.showForm(number.s[0].ta)} class="dropdown-item" href="#">
      <b>{number.s[0].d[0]}</b> 
      <p>Serial:{number.s[0].ta}</p>
    </a>
  ) : null
})}
    </div>
  </div>
  :null }
  <div style={{width:'300px'}}>
  <input type="text" class="form-control" aria-label="Text input with dropdown button" placeholder={this.state.serial} value={this.state.serial}/>
  </div>
</div>
{!this.state.showForm ?
<button className="btn btn-primary rounded-pill px-5" onClick={this.startCalibration}>Start calibration</button>
:null}
<div>
{this.state.showForm ?
          <div className="form-group mx-4">
            <form
            className="mt-5 py-5 px-5"
            autoComplete="off" 
            onSubmit={this.createNote} >
              
<p>First measurement</p>
              <div className="form-group">
            
              <div className="card" id="calibrationC">
                <div className="card-title">Current temperature</div>
                <h2>{this.state.last_one} °C</h2>
                </div>
             
              </div>
              <div className="form-group">
              <input
                className="form-control"
                placeholder="First measurement"
                onChange={this.handleChange}
                value={this.state.metemp1}
                name="metemp1"
              />
              </div>
              <button className="btn btn-primary rounded-pill px-5">Submit</button>
              </form>
              <button className="btn btn-primary rounded-pill px-5" onClick={this.endCalibration}>End Calibration</button>
          </div>
          :null 
        }
        
    {this.state.showForm2 ?
              <div className="form-group mx-4">
            <form
            className="mt-5 py-5 px-5"
            autoComplete="off" 
            onSubmit={this.createSecond} >
<p>Second measurement</p>
              <div className="form-group">
              
              <div className="card" id="calibrationC">
                <div className="card-title">Current temperature</div>
                <h2>{this.state.last_one} °C</h2>
                </div>
              
              {/* <input
                placeholder={this.state.last_one}
                className="form-control"
                onChange={this.handleChange}
                value={this.state.temp2}
                name="temp2"
              /> */}
            
              </div>
              <div className="form-group">
              <input
                placeholder="Second measurement"
                className="form-control"
                onChange={this.handleChange}
                value={this.state.metemp2}
                name="metemp2"
              />
              </div>
              <button className="btn btn-primary rounded-pill px-5">Submit</button>
            </form>
            <button className="btn btn-primary rounded-pill px-5" onClick={this.endCalibration}>End Calibration</button>
          </div>
              
          :null 
        }
          </div>
      
          </div>
          </div>
          </div>
        );
      }
}

/*
      showForm (value){
        this.setState({serial: value})
        const serials = [];
        const last_temps = [];
        const tempRef = db.ref('Devices/' + value);
        tempRef.orderByKey().limitToLast(1).on('value', (temps) => {
          const temparray = [];
          temps.forEach((temp) => {
            temparray.push(temp.val());
            console.log(temp.val());
          })
          const last_temp = temparray;
          last_temps.push({ l: last_temp[0][0] });
          console.log(last_temps);
          serials.push({ s: last_temps });
          this.setState({ last_temps: serials });
        })
      }

      {this.state.last_temps.map((number) => {
                return number.s && number.s.length?(
              <input
                className="form-control"             
                placeholder={number.s[0].l}
                onChange={this.handleChange}
                value={this.state.temp1}
                name="temp1"
              />
              ) : null
            })}
      */
