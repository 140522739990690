import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "../auth";

export default class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
          email: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
      }
     
      handleChange(event) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
      sendEmail(event){
        event.preventDefault();
        alert("An email has been sent to your email!")
        auth().sendPasswordResetEmail(this.state.email).then(function() {
          // Email sent.
          console.log('Email Sent');
        }).catch(function(error) {
          // An error happened.
        });
      }

    render(){
        return (
            <div className="container">
            <form
      className="mt-5 py-5 px-5"
      autoComplete="off"
      onSubmit={this.sendEmail}
    >
      <p className="lead">
        Enter email to reset password.
      </p>
      <div className="form-group">
        <input
          className="form-control"
          placeholder="Email"
          name="email"
          type="email"
          onChange={this.handleChange}
          value={this.state.email}
        ></input>
        </div>
        <button className="btn btn-primary rounded-pill px-5">Submit</button>
        </form>
        </div>
        )
    }
}