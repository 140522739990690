import React, { Component, ListItem } from "react";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import Header from "./Header";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import PrintSerial from "./printSerial"
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default class ChangeSettings extends Component {
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
      phone: "",
      alarm: "",
      report: "",
      devicename: "",
      numToAlarm: "",
      serials:[],
      timestamps: [],
      redirect: false,
      matches: window.matchMedia("(min-width: 768px)").matches
    };
    this.handleChange = this.handleChange.bind(this);
    this.createNote = this.createNote.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
    console.log(this.state.matches)
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }

  handlePhoneChange = value => {
    console.log(value);
    this.setState({ phone: value }, () => {
      console.log(this.state.phone);
    });
  };

   createNote() {
    //e.preventDefault();
    const uid = this.state.user.uid;
    const serialRef = db.ref(uid + '/serials');
    const devicesRef = db.ref("Devices")
    const { serial } = this.state; 
   serialRef.orderByChild("serial").equalTo(serial).once("value",snapshot => {
    if (snapshot.exists()){
      alert("This serial already exists")
    }
    else{
      devicesRef.once("value",snapshot => {
        if (snapshot.hasChild(serial)){
          db.ref(`${uid}/serials`)
          .push({
            serial,
          })
          const { phone } = this.state;
            const { alarm } = this.state;
            const { devicename } = this.state;
            const { report } = this.state;
            const { numToAlarm } = this.state;
            db.ref(`${serial}`)
                .set({
                  phone,
                  alarm,
                  devicename,
                  report,
                  numToAlarm
                })
                .then((_) => {
                  this.setState({serial: ""}, { phone: "" }, { alarm: "" }, { devicename: ""}, { report: ""}, { numToAlarm: ""});
                })
                .catch((error) => console.log(error.message));
        }
        else{
          alert("This device doesn't exist")
        }
      })
    }
})  
.then(() => this.setState({ redirect: true }));  
  }

  render() {
    const { redirect } = this.state;

     if (redirect) {
       return <Redirect to='/Profile'/>;
     }
    return (
      <div class="wrapper">
        <Sidenav/>
        <div id="content">
        {this.state.matches && (<Header/>)}
      {!this.state.matches && (<Navbar />)}
      <div className="form-group mx-4">
        {this.state.matches&&(
      <div className='container' style={{width:'600px'}}>
          <Card >
          <Card.Header style={{backgroundColor: "#eceff1"}}>	
      <h1 style={{marginTop: '7%', marginLeft: '5%', fontFamily: "Oswald"}}>Add Serial</h1>	
      </Card.Header>        <form
        className="mt-5 py-5 px-5"
        autoComplete="off" 
        onSubmit={this.createNote}>
          <div className="form-group">
          <input
            className="form-control"
            placeholder="Serial number"
            onChange={this.handleChange}
            value={this.state.serial}
            style={{width:'430px'}}
            name="serial"
          />
          </div>
          <div className="form-group">
          <input
            className="form-control"
            placeholder="Device name"
            onChange={this.handleChange}
            value={this.state.devicename}
            style={{width:'430px'}}
            name="devicename"
          />
          </div>
          <div className="form-group" >  
        <PhoneInput
	          masks={{si: '.. ... ... '}}	
            inputExtraProps={{	
              name: "phone",	
              required: true,	
              autoFocus: true,
          }}
          country={"si"}
          value={this.state.phone}
          onChange={this.handlePhoneChange}
          inputStyle={{width:'430px'}}
        />
      </div>
          <div className="form-group">
          <input
            placeholder="Alarm temperature"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.alarm}
            style={{width:'430px'}}
            name="alarm"
          />
          </div>
          <div className="form-group">
          <input
            placeholder="Report temperature"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.report}
            style={{width:'430px'}}
            name="report"
          />
          </div>
          <div className="form-group">
          <input
            placeholder="Number of measurements to trigger alarm"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.numToAlarm}
            style={{width:'430px'}}
            name="numToAlarm"
          />
          </div>
          <button className="btn btn-primary rounded-pill px-5">Submit</button>
        </form>
        </Card>
        </div>
        )}

        {
        !this.state.matches&&(
          <div className='container'>
          <Card >	
            <Card.Header style={{backgroundColor: "#eceff1"}}>	
      <h1 style={{marginTop: '7%', marginLeft: '5%', fontFamily: "Oswald"}}>Add Serial</h1>	
      </Card.Header>        <form
        className="mt-5 py-5 px-5"
        autoComplete="off" 
        onSubmit={this.createNote}>
          <div className="form-group">
          <input
            className="form-control"
            placeholder="Serial number"
            onChange={this.handleChange}
            value={this.state.serial}
            name="serial"
          />
          </div>
          <div className="form-group">
          <input
            className="form-control"
            placeholder="Device name"
            onChange={this.handleChange}
            value={this.state.devicename}
            name="devicename"
          />
          </div>
          <div className="form-group">
	        <PhoneInput	
          masks={{si: '.. ... ... '}}	
          inputExtraProps={{	
            name: "phone",	
            required: true,	
            autoFocus: true,	
          }}	
          country={"si"}	
          value={this.state.phone}	
          onChange={this.handlePhoneChange}	
          inputStyle={{width:'auto'}}	
        />
          </div>
          <div className="form-group">
          <input
            placeholder="Alarm temperature"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.alarm}
            name="alarm"
          />
          </div>
          <div className="form-group">
          <input
            placeholder="Report temperature"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.report}
            name="report"
          />
          </div>
          <div className="form-group">
          <input
            placeholder="Number of measurements to trigger alarm"
            className="form-control"
            onChange={this.handleChange}
            value={this.state.numToAlarm}
            name="numToAlarm"
          />
          </div>
          <button className="btn btn-primary rounded-pill px-5">Submit</button>
        </form>
        </Card>
        </div>
        )}
      </div>
      </div>
      </div>
    );
  }
}


/*
serialRef.on('value', (serial_numbers) => {
  serial_numbers.forEach((serial_number) => {
    const za = serial_number.val();
    const ta = za.serial;
    if (ta == "878"){
    }else{
      db.ref(`${uid}/serials`)
      .push({
        serial,
      })
    }
});
});
*/

/*
export default class Profile extends Component {
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      phone: "",
      serial: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.createNote = this.createNote.bind(this);
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }

  createNote() {
    const uid = this.state.user.uid;
    const { phone } = this.state;
    const { serial } = this.state;
    db.ref(`${uid}`)
      .set({
        phone,
        serial,
      })
      .then((_) => {
        this.setState({ phone: "" }, { serial: "" });
      })
      .catch((error) => console.log(error.message));
  }

  render() {
    return (
      <div className="form-group mx-4">
        <Header />
        <form onSubmit={this.createNote}>
          <p>Enter phone number:</p>
          <input
            className="form-control"
            onChange={this.handleChange}
            value={this.state.phone}
            name="phone"
          />
          <p>Serial number::</p>
          <input
            className="form-control"
            onChange={this.handleChange}
            value={this.state.serial}
            name="serial"
          />
          <input type="submit" />
        </form>
      </div>
    );
  }
}
*/

/*
import React, { Component } from "react";
import { auth, database } from "../firebase";

class ChangeSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user: auth().currentUser,
      phone: "",
      serial: "",
    };
    this.writeUserData = this.writeUserData.bind(this);
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };



  writeUserData = () => {
    database().ref("email").push(this.state.user.email);
    console.log("DATA SAVED");
  };

  render() {
    return (
      <form onSubmit={this.writeUserData}>
        <p>Enter phone number:</p>
        <input type="text" name="phone" onChange={this.myChangeHandler} />
        <p>Serial number::</p>
        <input type="text" name="serial" onChange={this.myChangeHandler} />
        <input type="submit" />
      </form>
    );
  }
}

export default ChangeSettings;
*/
