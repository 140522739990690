import React, { Component } from "react";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import Header from "./Header";

export default class Phone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      phone: "",
      alarm: "",
      serial: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.createNote = this.createNote.bind(this);
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: val });
  }

  createNote(e) {
    e.preventDefault();
    const uid = this.state.user.uid;
    console.log(uid);
    const { phone } = this.state;
    const { alarm } = this.state;
    let { serial } = this.state;
    const serialRef = db.ref(uid + "/serial");
    serialRef.once("value").then((dataSnapshot) => {
      console.log(dataSnapshot.val());
      this.setState({ serial: dataSnapshot.val() }, () => {
        serial = this.state.serial;
        console.log(serial);
        db.ref(`${serial}`)
          .set({
            phone,
            alarm,
          })
          .then((_) => {
            this.setState({ phone: "" }, { alarm: "" });
          })
          .catch((error) => console.log(error.message));
      });
    });
  }

  render() {
    return (
      <div className="form-group mx-4">
        <Header />
        <form onSubmit={this.createNote}>
          <p>Enter phone number:</p>
          <input
            className="form-control"
            onChange={this.handleChange}
            value={this.state.phone}
            name="phone"
          />
          <p>Enter alarm temperature:</p>
          <input
            className="form-control"
            onChange={this.handleChange}
            value={this.state.alarm}
            name="alarm"
          />
          <input type="submit" />
        </form>
      </div>
    );
  }
}
