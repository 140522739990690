import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Signup from "./pages/SignUp";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import ChangeSettings from "./pages/ChangeSettings";
import Table from "./pages/Table";
import Phone from "./pages/Phone";
import PrintSerial from "./pages/printSerial"
import ShowTable from "./pages/ShowTable.js"
import ChangeSerial from "./pages/ChangeSerial"
import ForgotPassword from "./pages/ForgotPassword"
import Calibration from "./pages/Calibration"
import User from "./pages/User"
import ChangePassword from "./pages/ChangePassword"
import Payement from "./pages/Payement"
import Gateway from "./pages/gateway"
import ManGateway from "./pages/ManGateway";
import { auth } from "./firebase";



function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/profile" />
        )
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
      verified: false,
    };
  }

  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false,
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false,
        });
      }
      console.log("authenticated:"+ this.state.authenticated)
    });
  }

  render() {
    return this.state.loading === true ? (
<h2 style={{color: 'black', fontFamily: "Syncopate", textAlign: 'center', marginTop: '20%'}}>MEASURES HUB</h2>
    ) : (
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <PrivateRoute
            path="/profile"
            authenticated={this.state.authenticated}
            component={Profile}
          ></PrivateRoute>
          <PrivateRoute
            path="/settings"
            authenticated={this.state.authenticated}
            component={Settings}
          ></PrivateRoute>
          <PrivateRoute
            path="/ChangeSettings"
            authenticated={this.state.authenticated}
            component={ChangeSettings}
          ></PrivateRoute>
          <PrivateRoute
            path="/table/:serialNumber"
            authenticated={this.state.authenticated}
            component={Table}
          ></PrivateRoute>
          <PrivateRoute
            path="/phone"
            authenticated={this.state.authenticated}
            component={Phone}
          ></PrivateRoute>
          <PrivateRoute
            path="/serialPrint"
            authenticated={this.state.authenticated}
            component={PrintSerial}
          ></PrivateRoute>
          <PrivateRoute
            path="/ShowTable"
            authenticated={this.state.authenticated}
            component={ShowTable}
          ></PrivateRoute>
          <PrivateRoute
            path="/ChangeSerial"
            authenticated={this.state.authenticated}
            component={ChangeSerial}
          ></PrivateRoute>
           {/* <PrivateRoute
            path="/Calibration"
            authenticated={this.state.authenticated}
            component={Calibration}
          ></PrivateRoute> */}
           <PrivateRoute
            path="/User"
            authenticated={this.state.authenticated}
            component={User}
          ></PrivateRoute>
           <PrivateRoute
            path="/ChangePassword"
            authenticated={this.state.authenticated}
            component={ChangePassword}
          ></PrivateRoute>
          <PrivateRoute
            path="/007"
            authenticated={this.state.authenticated}
            component={Payement}
          ></PrivateRoute>
           {/* <PrivateRoute
            path="/AddGateway"
            authenticated={this.state.authenticated}
            component={Gateway}
          ></PrivateRoute>
            <PrivateRoute
            path="/GatewayManager"
            authenticated={this.state.authenticated}
            component={ManGateway}
          ></PrivateRoute> */}
          <PublicRoute
            path="/signup"
            authenticated={this.state.authenticated}
            component={Signup}
          ></PublicRoute>
          <PublicRoute
            path="/login"
            authenticated={this.state.authenticated}
            component={Login}
          ></PublicRoute>
          <PublicRoute
            path="/ForgotPassword"
            authenticated={this.state.authenticated}
            component={ForgotPassword}
          ></PublicRoute>
        </Switch>
      </Router>
    );
  }
}

export default App;

/*
import Plotly from "plotly.js-basic-dist";

import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

class App extends React.Component {
  state = {
    line1: {
      x: [-3, -2, -1],
      y: [1, 2, 3],
      name: "Line 1",
    },
    line2: {
      x: [1, 2, 3],
      y: [-3, -2, -1],
      name: "Line 2",
    },
    layout: {
      datarevision: 0,
    },
    revision: 0,
  };
  rand = () => parseInt(Math.random() * 10, 10);
  increaseGraphic = () => {
    const { line1, line2, layout } = this.state;
    line1.x.push(this.rand());
    line1.y.push(this.rand());
    line2.x.push(this.rand());
    line2.y.push(this.rand());
    this.setState({ revision: this.state.revision + 1 });
    layout.datarevision = this.state.revision + 1;
  };
  render() {
    return (
      <div>
        <Plot
          data={[this.state.line1, this.state.line2]}
          layout={this.state.layout}
          revision={this.state.revision}
          graphDiv="graph"
        />
      </div>
    );
  }
}

export default App;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
