import React, { Component, ListItem } from "react";
import { auth, db } from "../firebase";
import Header from "./Header";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";

export default class Gateway extends Component {
    constructor() {
        super();
        this.state = {
          user: auth().currentUser,
          gateway: "",
          gateways:[],
          redirect: false,
          showForm: false,
          matches: window.matchMedia("(min-width: 768px)").matches
        };
        this.handleChange = this.handleChange.bind(this);
        this.createNote = this.createNote.bind(this);
      }

      componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
        const uid = this.state.user.uid;
    const gatewayRef = db.ref(uid + '/gateways');
  
    gatewayRef.on('value', (gateways_nums) => {
      const gateways = [];
      gateways_nums.forEach((gateway_num) => {
        const za = gateway_num.val();
        const ta = za.gateway;
        gateways.push(ta);
      });
      this.setState({ gateways: gateways });
    });

    // const gw = db.ref('gateways')
    // gw.once('value', (devs)=>{
    //   devs.forEach((dev)=>{
    //   dev.forEach((ble)=>{
    //     console.log(ble.val().serial)

    //   })
    //   })
    // })

    // const payRef = db.ref("payement")
    // payRef.once('value', (serials)=>{
    //   serials.forEach((serial)=>{
    //     console.log(serial.key)
    //     if (serial.val()["payement"] === "1"){
    //       const serialRef = db.ref("Devices/"+serial.key)
    //       if (serial.key != 804){
    //       serialRef.orderByKey().limitToLast(1).once('value', (temps) => {
    //         temps.forEach((temp)=> {
    //           console.log(temp.val()[0])
    //           const alarmRef = db.ref(serial.key + "/alarm")
    //           alarmRef.once('value', (alarm)=>{
    //             console.log(alarm.val())
    //             if(temp.val()[0] > alarm.val()){
    //               console.log("PRVA PREVISOKA")
    //               var a = 0;
    //               serialRef.orderByKey().limitToLast(3).once('value', (prevTemps) => {
    //                 prevTemps.forEach((prevTemp)=> {
    //                   //console.log(serial.key)
    //                   console.log(prevTemp.val()[0])
    //                   if (prevTemp.val()[0] < alarm.val()){
    //                     a = a +1;
    //                   }
    //                 })
    //                 console.log(a)
    //                 if (a < 1){
    //                   const alarmRef = db.ref(serial.key + '/phone')
    //                   alarmRef.once('value', (pname)=>{
    //                   var telefon = "+" + pname.val();
    //                  console.log(telefon)
    //                  db.ref(`Alarms/${serial.key}`)
    //                  .set({
    //                    0: 1,
    //                  })
    //                     })
    //                 }
    //               })
    //             }
    //             else{
    //               const aRef = db.ref('Alarms/'+serial.key)
    //               aRef.once('value', (ases) =>{
    //                 console.log(ases.val()['0'])
    //                 if (ases.val()['0'] > 0){
    //                   db.ref(`Alarms/${serial.key}`).set({0: 0,})
    //                 }
    //               })
    //             }
    //           })
    //         })
    //       })
    //     }
    //     else{
    //       console.log("JE 804")
    //     }
    //     }
    //   })
    // })

    const payRef = db.ref("payement")
    payRef.once('value', (serials)=>{
      serials.forEach((serial)=>{
        if (serial.val()["payement"] === "1"){
          const serialRef = db.ref("Devices/"+serial.key)
          if(serial.key != 804){
          serialRef.orderByKey().limitToLast(1).on('value', (temps) => {
            //console.log(temps.val())
            temps.forEach((temp)=> {
              //console.log(temp.val()[1])
              // if (Date.now() - temp.val()[1] >= 1800000 && Date.now() - temp.val()[1] < 12600000){
              if (Date.now() - temp.val()[1] >= 2100000 && Date.now() - temp.val()[1] < 5700000){
                console.log("yes")
                const alarmRef = db.ref(serial.key + '/phone')
                alarmRef.on('value', (pname)=>{
                var telefon = "+" + pname.val();
                  })
              }
              else {
                console.log("No")
              }
            })
          })
        }
        else{
          console.log("JE 804")
        }
        }
      })
    })
      }

      handleChange(e) {
        let nam = e.target.name;
        let val = e.target.value;
        this.setState({ [nam]: val });
      }

    
      createNote() {
        const uid = this.state.user.uid;
        const gatewayRef = db.ref(uid + '/gateways');
        const devicesRef = db.ref("Devices")
        const { gateway } = this.state; 
       gatewayRef.orderByChild("gateway").equalTo(gateway).once("value",snapshot => {
        if (snapshot.exists()){
          alert("This gateway already exists")
        }
        else{
              db.ref(`${uid}/gateways`)
              .push({
                gateway,
              })
        }
    })  
    .then(() => this.setState({ redirect: true }));  
      }
    
      render() {
        const { redirect } = this.state;

        if (redirect) {
          return <Redirect to='/Profile'/>;
        }
        return (
          <div class="wrapper">
             <Sidenav/>
             <div id="content">
             {this.state.matches && (<Header/>)}
      {!this.state.matches && (<Navbar />)}


          <div className="form-group mx-4">
            {this.state.matches &&(
          <div className='container' style={{width:'600px'}}>
          <Card >
          <Card.Header style={{backgroundColor: "#eceff1"}}>
      <h1 style={{marginTop: '7%', marginLeft: '5%', fontFamily: "Oswald"}}>Add Gateway</h1>
      </Card.Header>
            <form
            className="mt-5 py-5 px-5"
            autoComplete="off" 
            onSubmit={this.createNote} >
              <div className="form-group">
                  <input
                  className="form-control"
                  placeholder="Gateway number"
                  onChange={this.handleChange}
                  value={this.state.gateway}
                  style={{width:'460px'}}
                  name="gateway"
                />
              </div>
                   <div className="form-group" >  
              </div>
              <button className="btn btn-primary rounded-pill px-5">Submit</button>
            </form>
            </Card>  
            </div>
            )}
            
            {!this.state.matches &&(
              <div className='container' >
          <Card >
          <Card.Header style={{backgroundColor: "#eceff1"}}>
      <h1 style={{marginTop: '7%', marginLeft: '5%', fontFamily: "Oswald"}}>Add Gateway</h1>
      </Card.Header>
            <form
            className="mt-5 py-5 px-5"
            autoComplete="off" 
            onSubmit={this.createNote} >
                  <div className="form-group">
                      <input
                      className="form-control"
                      placeholder="Gateway number"
                      onChange={this.handleChange}
                      value={this.state.gateway}
                      name="devicename"
                    />
                  </div>
                  <div className="form-group" >  
      </div>
                  <button className="btn btn-primary rounded-pill px-5">Submit</button>
                </form>
                </Card>  
                </div>
            )}
          </div>
          
          </div>
       
          </div>
        );
      }
}