import React, { Component } from "react";
import { Redirect } from 'react-router'
import Header from "./Header";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import PrintSerial from "./printSerial";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import Table from "./Table"
import "./Sidebar.css";

export default class Profile extends Component {
  constructor() {
    super();
    this.state = {
      user: auth().currentUser,
      serial: "",
          serials: [],
          timestamps:[],
        showComponent: false,
        matches: window.matchMedia("(min-width: 768px)").matches
    };
  }

  componentDidMount() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
    console.log(this.state.matches)
  }
  
  render() {
    return (
      <div class="wrapper">
    <Sidenav/>
    <div id="contentDashboard">
      <div id="bluebg">
      {this.state.matches && (<Header/>)}
      {!this.state.matches && (<Navbar />)}
      <div class="cart">
        <PrintSerial/>
        </div>
        </div>
    </div>
    </div>
    );
  }
}
/*
return (
      <div class="wrapper">
    <Sidenav handleSelect={this.handleSelect.bind(this)} se={this.state.serials}/>
    <div id="content">
      {this.state.matches && (<Header/>)}
      {!this.state.matches && (<Navbar handleSelect={this.handleSelect.bind(this)} se={this.state.serials}/>)}
      Logged in as: <strong>{this.state.user.email}</strong>
        <PrintSerial/>
        {this.state.showComponent ?
<Table s={this.state.serial}/>:
null 
}
    </div>
</div>
    );

       
      */