import React, { Component, ListItem } from "react";
import { auth, db } from "../firebase";
import Header from "./Header";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import GatewayMap from "./gatewayMap";

export default class ManGateway extends Component {
    constructor() {
        super();
        this.state = {
          user: auth().currentUser,
          gateway: "",
          gateways:[],
          serials: [],
          redirect: false,
          showForm: false,
          matches: window.matchMedia("(min-width: 768px)").matches
        };
      }

      componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
        console.log(this.state.matches)
      }
      
      render() {
        return (
          <div class="wrapper">
        <Sidenav/>
        <div id="contentDashboard">
          <div id="bluebg">
          {this.state.matches && (<Header/>)}
          {!this.state.matches && (<Navbar />)}
          <div class="cart">
            <GatewayMap/>
            </div>
            </div>
        </div>
        </div>
        );
      }
    }