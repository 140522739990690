import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyD3oOy--g9XE2PZ-_mWy7Nr-A7qWRwPTL4",
  authDomain: "node-c5ec0.firebaseapp.com",
  databaseURL: "https://node-c5ec0.firebaseio.com",
  projectId: "node-c5ec0",
  storageBucket: "node-c5ec0.appspot.com",
  messagingSenderId: "453780186172",
  appId: "1:453780186172:web:e0b112228673de13d24f39",
  measurementId: "G-62XHD3178F",
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.database();
export const auth = firebase.auth;

