import React, { Component, ListItem } from "react";
import { auth, db } from "../firebase";
import Header from "./Header";
import Sidenav from "./Sidebar";
import Navbar from "./Navbar";
import "./Sidebar.css";
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import {  faTrashAlt } from '@fortawesome/free-solid-svg-icons'

export default class GatewayMap extends Component {
    constructor() {
        super();
        this.state = {
          user: auth().currentUser,
          gateway: "",
          gateways:[],
          serials: [],
          last_temps: [],
          redirect: false,
          showForm: false,
          matches: window.matchMedia("(min-width: 768px)").matches
        };
      }

    //   componentDidMount() {
    //     const handler = e => this.setState({matches: e.matches});
    //     window.matchMedia("(min-width: 768px)").addListener(handler);
    //     const uid = this.state.user.uid;
    //     const gatewayRef = db.ref(uid + '/gateways');
        
    //     gatewayRef.once('value', (gateways_nums) => {
           
    //         const serialsArr = [];
    //       gateways_nums.forEach((gateway_num) => {
    //         const serialRef = db.ref('gateways/' + gateway_num.val()['gateway'])
    //         const last_temps =[];
    //         serialRef.once('value', (serials)=>{ 
    //             console.log(gateway_num.val()['gateway']);
    //             const ser = []
                
               
    //             serials.forEach((serial)=>{
    //                 console.log(serial.val()['serial'])
    //                 ser.push(serial.val()['serial'])
    //                 console.log(ser)
    //             })
    //             last_temps.push({a: gateway_num.val()['gateway'], b: ser})
    //             console.log(last_temps)
               
    //             //console.log(serialsArr)
    //         })
    //         serialsArr.push({s:last_temps})
    //       });
    //       console.log('serial arr')
    //       console.log(serialsArr)
    //       this.setState({serials:serialsArr})
    //     });
    //     //console.log(serials)
    //     //this.setState({serials:serialsArr})
    //   }

    componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addListener(handler);
        const uid = this.state.user.uid;
        const serialRef = db.ref(uid + '/gateways');
    
        serialRef.on('value', (serial_numbers) => {
          const serials = [];
          serial_numbers.forEach((serial_number) => {
            //console.log(serial_number.val());
            const za = serial_number.val()['gateway'];
            console.log(za);
            const last_temps = [];
            const devicename = [];
            const phone = [];
            const alarm = [];
            const gwRef = db.ref('gateways/' + za)
            // const nameRef = db.ref(ta + '/devicename');
            // nameRef.on('value', (dname)=> {
            //   devicename.push(dname.val());
            //   this.setState(devicename );
            // })
            // const phoneRef = db.ref(ta + '/phone')
            // phoneRef.on('value', (pname)=> {
            //   phone.push(pname.val())
            // })
            // const alarmRef = db.ref(ta + '/alarm')
            // alarmRef.on('value', (aname) =>{
            //  alarm.push(aname.val())
            // })
            const tempRef = db.ref('gateways/' + za);
            tempRef.orderByChild("serial").on('value', (temps) => {
              const temparray = [];
              temps.forEach((temp) => {
                temparray.push(temp.val());
                console.log(temp.val())
              })
              const last_temp = temparray;
              last_temps.push({ l: last_temp, za });
              this.setState({ last_temps: last_temps })
            })
            //console.log(last_temps);
            serials.push({ s: last_temps });
            //console.log(serials);
          });
          this.setState({ serials: serials });
        });
      }

      render() {
        if(!this.state.serials) {
            return( <h2>Loading</h2>)
        }
        else{
        return (
            <div>
            <div id="content">
          {console.log(this.state.serials)}
          
          {this.state.serials.map((number) => {
              console.log(JSON.stringify(this.state.serials))
              //console.log(number.s[0].l[0]['serial'])
    return number.s && number.s.length? 
    (
      <div className="col-md-6">
    <Card className="card" style={{ width: '60%' }}>
      <Card.Body>
        <div className="row">
        <div className="col" >
        <h2 > {number.s[0].za}</h2>
        {/* <h2>{number.s[0].l['serial']}</h2> */}
      
        {number.s[0].l.map(option => 
        <div>
          <p>{option.serial}</p>
          </div>
        )}
    
        </div>
        </div>
        </Card.Body>
    </Card>
    <p></p>
    </div>
  ) : null
})}
          </div>
          </div>
        )
    }
}
    }